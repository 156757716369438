/* Crypto Modal Styles */
.modal-content {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-bottom: 1px solid #eee;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.close {
  font-size: 1.5rem;
  color: #aaa;
  background: transparent;
  border: none;
}

.close:hover {
  color: #777;
}

.modal-body {
  padding: 20px;
}

.payment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-qr-code img {
  max-width: 200px;
  margin-bottom: 20px;
  border: 1px solid #eee;
  padding: 10px;
  background: #f9f9f9;
}

.payment-info {
  text-align: center;
}

.payment-info h5 {
  color: #333;
  margin-bottom: 15px;
}

.payment-info div {
  margin-bottom: 10px;
  font-size: 0.95rem;
}

strong {
  color: #555;
}

.copy-btn {
  margin-left: 10px;
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: 4px;
}

.copy-btn:hover {
  background-color: #45a049;
}

.modal-footer {
  border-top: 1px solid #eee;
  padding: 20px;
  text-align: right;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 15px;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

/* Existing styles above... */

.note-footer {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.note-footer a {
  color: #007bff;
  text-decoration: none;
}

.note-footer a:hover {
  text-decoration: underline;
}
